import Dexie from "dexie";

/**
 * @typedef {{
 *   id: string,
 *   backendApiName: string,
 *   data: object,
 * }} RequestsQueue
 *
 * @typedef {{
 *   userId: string,
 *   date: string,
 *   time: string,
 *   category: import("~/libs/constants").NotificationCategory,
 *   message: string,
 * }} NotificationHistory
 *
 * @typedef {{
 *   trackingNumber: string,
 *   backendApiName: string,
 *   data: object,
 * }} RequestsQueueForRetry
 *
 * @typedef {{
 *   trackingNumber: string,
 *   personalMemoPhoto: string,
 * }} AdditionalDataForDeliverylist
 */

export class IndexedDbConnectErrorException extends Error {
  constructor() {
    super("IndexedDB is not connected");
    this.name = "IndexedDbConnectErrorException";
  }
}

class OitocDatabase extends Dexie {
  /** @type {import("dexie").Dexie.Table<RequestsQueue, string>} */
  requestsQueue;

  /** @type {import("dexie").Dexie.Table<NotificationHistory, number>} */
  notificationHistory;

  /** @type {import("dexie").Dexie.Table<RequestsQueueForRetry, string>} */
  requestsQueueForRetry;

  /** @type {import("dexie").Dexie.Table<AdditionalDataForDeliverylist, string>} */
  additionalDataForDeliverylist;

  constructor() {
    super("oitocDatabase");
  }
}

export const db = new OitocDatabase();
db.version(1).stores({
  requestsQueue: "id",
});

db.version(2).stores({
  requestsQueue: "id",
  notificationHistory: "++id, userId, date, time",
});

db.version(3).stores({
  requestsQueue: "id",
  notificationHistory: "++id, userId, date, time",
  requestsQueueForRetry: "++id, trackingNumber",
});

db.version(4).stores({
  requestsQueue: "id",
  notificationHistory: "++id, userId, date, time",
  requestsQueueForRetry: "++id, trackingNumber",
  additionalDataForDeliverylist: "trackingNumber",
});
