<script>
  import List, { Item, Separator } from "@smui/list";
  import { escape } from "html-escaper";
  import { getContext, onMount } from "svelte";
  import { fade } from "svelte/transition";

  import Footer from "~/components/Footer.svelte";
  import Header from "~/components/Header.svelte";
  import { CONTEXT_KEY_USER, NotificationCategory } from "~/libs/constants";
  import notificationHistoryUtils from "~/libs/notificationHistoryUtils";

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  const DAY_OF_WEEK = ["日", "月", "火", "水", "木", "金", "土"];

  /**
   * @type {{
   *  category: import("~/libs/constants").NotificationCategory,
   *  message: string,
   *  date: string,
   * }[]}
   */
  let displayNotificationList = [];

  onMount(async () => {
    const notificationList = await notificationHistoryUtils.getHistory(
      userContext.loginUser.username,
    );
    for (let i = 0; i < notificationList?.length; i++) {
      const date = new Date(
        Number(notificationList[i].date.substring(0, 4)),
        Number(notificationList[i].date.substring(4, 6)) - 1,
        Number(notificationList[i].date.substring(6)),
        Number(notificationList[i].time.substring(0, 2)),
        Number(notificationList[i].time.substring(2, 4)),
        Number(notificationList[i].time.substring(4)),
      );
      const dateStr = date.toLocaleString();
      displayNotificationList.push({
        category: notificationList[i].category,
        message: notificationList[i].message,
        date: `${dateStr.split(" ")[0]}(${DAY_OF_WEEK[date.getDay()]}) ${dateStr.split(" ")[1]}`,
      });
    }
    displayNotificationList = displayNotificationList;
  });
</script>

<div class="mainContentsWrapper">
  <!-- ヘッダー -->
  <Header>
    <svelte:fragment slot="center">お知らせ</svelte:fragment>
  </Header>

  <main in:fade>
    {#if displayNotificationList.length}
      <div class="notificationList">
        <List nonInteractive>
          {#each displayNotificationList as notification}
            <Item style="height:fit-content;">
              <div class="notificationItem">
                <div class="categoryBatch">
                  {#if notification.category == NotificationCategory.INFO}
                    <p class="infoCategory">INFO</p>
                  {:else if notification.category == NotificationCategory.ERROR}
                    <p class="errorCategory">エラー</p>
                  {:else if notification.category == NotificationCategory.PUSH}
                    <p class="pushCategory">通知</p>
                  {/if}
                </div>
                <div class="messageArea">
                  <p class="messageText">
                    {@html escape(notification.message).replace(
                      /\n/g,
                      "<br />",
                    )}
                  </p>
                </div>
                <div class="displayTimeArea">
                  <p class="displayTime">{notification.date}</p>
                </div>
              </div>
            </Item>
            <Separator />
          {/each}
        </List>
      </div>
    {:else}
      <div class="noneNotification">
        <span class="material-icons icon">speaker_notes_off</span>
        <span class="noneNotificationText">表示するお知らせがありません</span>
      </div>
    {/if}
  </main>

  <Footer />
</div>

<style lang="scss">
  main {
    display: flex;
    flex-direction: column;
  }

  .notificationItem {
    height: fit-content;
    width: 100%;
  }

  .categoryBatch {
    margin-top: 9px;
    display: columns;
    height: 16px;
    width: 100px;
    text-align: center;
    font-size: 11px;
    line-height: 20px;
  }

  .infoCategory {
    border-radius: 10px;
    background-color: rgb(168, 249, 183);
  }

  .errorCategory {
    border-radius: 10px;
    background-color: rgb(253, 180, 196);
  }

  .pushCategory {
    border-radius: 10px;
    background-color: rgb(176, 221, 252);
  }

  .messageArea {
    width: 100%;
    margin: 14px 0 8px;
  }

  .messageText {
    font-size: 14px;
    line-height: 20px;
  }

  .displayTimeArea {
    text-align: right;
    margin-bottom: 8px;
  }

  .displayTime {
    color: rgb(105, 105, 105);
    font-size: 13px;
    line-height: 11px;
  }

  .noneNotification {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;

    .icon {
      font-size: 150px;
      color: #d9d9d9;
    }

    .noneNotificationText {
      margin-top: 20px;
      color: #aaaaaa;
    }
  }
</style>
