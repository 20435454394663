import { STATUS_DELIVERED, STATUS_OUT_FOR_DELIVERY } from "~/libs/constants";
import deliveryListUtils from "~/libs/deliveryListUtils";
import { needToReload } from "~/libs/stores";

const svelteContextMigrator = {
  /**
   * AppContextのデータ構造を現行アプリにあわせてマイグレーションする。
   * @param {import("~/libs/commonTypes").AppContext} appContext
   */
  migrateAppContext(appContext) {
    /** 現行アプリのAppContext互換性バージョン */
    const currentAppContextVersion = 3;
    let modified = false;

    /** @type {Array<import("~/libs/commonTypes").TimeFramePreset>} */
    const timeFramePresetList = [
      {
        name: "デフォルト",
        timeFrameList: ["0912", "1215", "1518", "1821"],
      },
    ];

    if (appContext.version === 0) {
      /* localStorageにデータがない場合（AppContextをnewしただけの状態） */
      appContext.version = currentAppContextVersion;
      appContext.timeFramePresetList = timeFramePresetList;
      modified = true;
    } else {
      /* localStorageからデータを書き戻した場合 */

      // バージョン指定なしからバージョン1に移行
      if (!Number.isInteger(appContext.version)) {
        // データ移行は不要
        appContext.version = 1;
        modified = true;
      }

      // バージョン1からバージョン2に移行
      if (appContext.version === 1) {
        if (typeof appContext.timeFramePresetList === "undefined") {
          appContext.timeFramePresetList = timeFramePresetList;
        }

        appContext.version = 2;
        modified = true;
      }

      // バージョン2からバージョン3に移行
      if (appContext.version === 2) {
        if (appContext.firstDeliveryCompleteOpened === true) {
          appContext.firstNonDeliveryOpened = true;
          appContext.firstTakeOverToDriverOpened = true;
        }

        appContext.version = 3;
        modified = true;
      }
    }

    if (modified) {
      appContext.store();
    }
  },

  /**
   * UserContextのデータ構造を現行アプリにあわせてマイグレーションする。
   * @param {import("~/libs/commonTypes").UserContext} userContext
   */
  migrateUserContext(userContext) {
    /** 現行アプリのUserContext互換性バージョン */
    const currenUserContextVersion = 5;
    let modified = false;

    if (userContext.version === 0) {
      /* localStorageにデータがない場合（UserContextをnewしただけの状態） */
      userContext.version = currenUserContextVersion;
      modified = true;
    } else {
      /* localStorageからデータを書き戻した場合 */

      // バージョン指定なしからバージョン1に移行
      if (!Number.isInteger(userContext.version)) {
        delete userContext["unloadingPackageList"];
        delete userContext["numberOfPackagesDisplayedOnMap"];

        if (userContext.deliveryList) {
          for (const deliveryPackage of userContext.deliveryList) {
            // @ts-ignore
            deliveryPackage.statusText = deliveryPackage.status;

            if (deliveryPackage.statusText === "未") {
              deliveryPackage.status = STATUS_OUT_FOR_DELIVERY;
            } else if (deliveryPackage.statusText === "済") {
              deliveryPackage.status = STATUS_DELIVERED;
              deliveryPackage.deliveredTimestamp = Date.now();
            } else {
              // 元の配送ステータスが不明のため、持出中として仮設定する（後続の配達リスト表示でBEから再取得）
              deliveryPackage.status = STATUS_OUT_FOR_DELIVERY;
            }

            // 住所から緯度経度を算出する仕組みが変更されているため再計算（同期せずに非同期で実行）
            deliveryListUtils.calculateAndSetLatLon(deliveryPackage);
          }
        }

        // 次回の配達リスト表示時、強制的にバックエンドと同期
        needToReload.set(true);

        userContext.version = 1;
        modified = true;
      }

      // バージョン1からバージョン2に移行
      if (userContext.version === 1) {
        // 次回の配達リスト表示時、強制的にバックエンドと同期
        needToReload.set(true);

        userContext.version = 2;
        modified = true;
      }

      // バージョン2からバージョン3に移行
      if (userContext.version === 2) {
        if (userContext.deliveryList) {
          for (const deliveryPackage of userContext.deliveryList) {
            if (Number.isInteger(deliveryPackage.statusText)) {
              // @ts-ignore
              if (deliveryPackage.statusText === STATUS_OUT_FOR_DELIVERY) {
                deliveryPackage.statusText = "未";
              }
              // @ts-ignore
              else if (deliveryPackage.statusText === STATUS_DELIVERED) {
                deliveryPackage.statusText = "済";
              } else {
                // 持出中として仮設定（後続の配達リスト表示でBEから再取得した際にリストから削除される）
                deliveryPackage.statusText = "未";
              }
            }
          }
        }

        // 次回の配達リスト表示時、強制的にバックエンドと同期
        needToReload.set(true);

        userContext.version = 3;
        modified = true;
      }

      // バージョン3からバージョン4に移行
      if (userContext.version === 3) {
        delete userContext["previouslySelectedDepot"];

        if (userContext.deliveryList) {
          for (const deliveryPackage of userContext.deliveryList) {
            // @ts-ignore
            if (deliveryPackage.customerId) {
              // @ts-ignore
              deliveryPackage.customer = deliveryPackage.customerId;
              // @ts-ignore
              delete deliveryPackage.customerId;
            }
          }
        }

        userContext.version = 4;
        modified = true;
      }

      // バージョン4からバージョン5に移行
      if (userContext.version === 4) {
        if (userContext.deliveryList) {
          deliveryListUtils.numberingAllPackages(
            userContext.deliveryList,
            userContext.syncFailureList ?? [],
          );
        }

        modified = true;
      }
    }

    if (modified) {
      userContext.store();
    }
  },
};
export default svelteContextMigrator;
