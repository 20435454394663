import { format as formatDate } from "date-fns";

import { BackendApiName } from "~/libs/constants";
import { db } from "~/libs/db";
import logger from "~/libs/logger";

const offlineBackendApi = {
  /**
   * ログイン
   * @param {{username: string, password: string}} data
   */
  login(data) {
    try {
      addRequestsQueue(BackendApiName.LOGIN, data);
    } catch (error) {
      // ログインのリクエスト登録に失敗した場合、ログのみ出力して継続
      // (オンライン切り替え時に再ログインの流れになるため問題なし)
      logger.error(
        "[offlineBackendApi] ログインAPIのリクエスト登録に失敗しました。",
        {
          username: data.username,
        },
        error,
      );
    }
  },

  /**
   * 配送ステータスの更新
   * @param {[string, FormDataEntryValue][]} data
   */
  updateShipmentStatus(data) {
    addRequestsQueue(BackendApiName.UPDATE_SHIPMENT_STATUS, data);
  },
};
export default offlineBackendApi;

/**
 * indexedDBにリクエスト情報を登録する。
 * @param {string} backendApiName
 * @param {object} data
 */
function addRequestsQueue(backendApiName, data) {
  const id = formatDate(new Date(), "yyyyMMddHHmmss");
  const record = {
    id: id,
    backendApiName: backendApiName,
    data: data,
  };

  db.requestsQueue.add(record);
}
