import { format as formatDate } from "date-fns";

import { db } from "~/libs/db";
import logger from "~/libs/logger";
import { toast } from "~/libs/toast";

const notificationHistoryUtils = {
  /**
   * 通知履歴DBにデータを追加する。
   * @param {string} userId
   * @param {string} date
   * @param {string} time
   * @param {import("~/libs/constants").NotificationCategory} category
   * @param {string} message
   * @returns {Promise<number>} 追加されたレコードの番号（DexieのEntityTableの主キーで連番）
   */
  addHistory(userId, date, time, category, message) {
    const record = {
      userId: userId,
      date: date,
      time: time,
      category: category,
      message: message,
    };
    return db.notificationHistory.add(record);
  },

  /**
   * 古い履歴の有無をチェックし、必要に応じて削除した上で新しい履歴を追加する。
   * @param {string} userId
   * @param {import("~/libs/constants").NotificationCategory} category
   * @param {string} message
   * @returns {Promise<number>} 追加されたレコードの番号（DexieのEntityTableの主キーで連番）
   */
  async deleteAndAddHistory(userId, category, message) {
    const border = formatDate(
      new Date().setDate(new Date().getDate() - 7),
      "yyyyMMdd",
    );
    try {
      if (await this.hasOldHistory(userId, border)) {
        await this.deleteOldHistory(userId, border);
      }
      const date = formatDate(new Date(), "yyyyMMdd");
      const time = formatDate(new Date(), "HHmmss");
      return await this.addHistory(userId, date, time, category, message);
    } catch (error) {
      // お知らせの登録に失敗した場合、ログを表示してエラーをスローする
      logger.error(
        "[notificationHistoryUtils] お知らせの登録に失敗しました。",
        {
          username: userId,
        },
        error,
      );
      throw error;
    }
  },

  /**
   * 保持期間(7日間)よりも古いデータがある場合に真を返す。
   * @param {string} userId
   * @param {string} border
   * @returns {Promise<boolean>}
   */
  async hasOldHistory(userId, border) {
    const oldHistoryNum = await db.notificationHistory
      .where({ userId: userId })
      .and((n) => n.date < border)
      .count();
    return oldHistoryNum > 0;
  },

  /**
   * 保持期間7日間よりも前のデータ有無をチェックし、
   * 有る場合は削除する。
   * @param {string} userId
   * @param {string} border
   */
  async deleteOldHistory(userId, border) {
    await db.notificationHistory
      .where({ userId: userId })
      .and((n) => n.date < border)
      .delete();
  },

  /**
   * 日時の降順でソートした通知履歴を返す。
   * @param {string} userId
   * @returns {Promise<Array<import("~/libs/db").NotificationHistory>>}
   */
  async getHistory(userId) {
    try {
      const notificationList = await db.notificationHistory
        .orderBy("id")
        .reverse()
        .filter((n) => n.userId == userId)
        .toArray();
      return notificationList;
    } catch (error) {
      // お知らせの取得に失敗した場合、ログ・トーストを表示して継続
      logger.error(
        "[notificationHistoryUtils] お知らせの取得に失敗しました。",
        {
          username: userId,
        },
        error,
      );
      toast.error(
        "お知らせの取得時にエラーが発生しました。<br />エラーが続く場合はアプリや端末の再起動をお試しください。",
      );
    }
  },
};

export default Object.freeze(notificationHistoryUtils);
