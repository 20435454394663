<div class="readOnlyIcon">
  <span class="material-icons"> lock </span>
  読取専用<br />モード
</div>

<style lang="scss">
  .readOnlyIcon {
    display: flex;
    background-color: var(--mdc-theme-secondary);
    color: #ffffff;
    margin-left: 0.2rem;
    padding: 0.25rem 0.3rem;
    border-radius: 0.25rem;
    font-size: 0.75rem;
    font-weight: bold;
    text-align: center;

    .material-icons {
      margin: 0 0.2rem 0 -0.1rem;
    }
  }
</style>
