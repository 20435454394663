import { db } from "~/libs/db";
import logger from "~/libs/logger";
import { toast } from "~/libs/toast";

const additionalDataForDeliverylist = {
  /**
   * 個人メモ用画像を登録/更新する
   * @param {string} trackingNumber
   * @param {string} personalMemoPhoto
   * @param {string} username
   */
  async registPersonalMemoPhoto(trackingNumber, personalMemoPhoto, username) {
    try {
      if (await hasRecord(trackingNumber, username)) {
        await update(
          trackingNumber,
          { personalMemoPhoto: personalMemoPhoto },
          username,
        );
      } else {
        const record = {
          trackingNumber: trackingNumber,
          personalMemoPhoto: personalMemoPhoto,
        };
        await regist(record, username);
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  /**
   * 送り状番号を指定して個人メモ用画像を取得する
   * @param {string} trackingNumber
   * @param {string} username
   * @returns {Promise<string>} 個人メモ用画像(登録が無い場合は空文字)
   */
  async getPersonalMemoPhoto(trackingNumber, username) {
    try {
      if (await hasRecord(trackingNumber, username)) {
        return await get(trackingNumber, username).then(
          (record) => record?.personalMemoPhoto,
        );
      } else {
        return "";
      }
    } catch (error) {
      console.log(error);
      toast.error(
        "個人メモ画像の取得に失敗しました。<br />エラーが続く場合はアプリや端末の再起動をお試しください。",
      );

      return "";
    }
  },

  /**
   * 送り状番号を指定してデータを削除する
   * @param {string} trackingNumber
   * @param {string} username
   */
  async deleteByTrackingNumber(trackingNumber, username) {
    if (await hasRecord(trackingNumber, username)) {
      try {
        await db.additionalDataForDeliverylist.delete(trackingNumber);
      } catch (error) {
        // データの削除に失敗した場合、ログを表示してエラーをスローする
        logger.error(
          "[additionalDataForDeliverylist] 個人メモ画像のデータの削除に失敗しました。",
          {
            username: username,
          },
          error,
        );
        throw error;
      }
    }
  },

  /**
   * 全てのデータを削除する
   * @param {string} username
   */
  async deleteAll(username) {
    try {
      const allRecords = await db.additionalDataForDeliverylist.toArray();
      allRecords.forEach((record) => {
        db.additionalDataForDeliverylist.delete(record.trackingNumber);
      });
    } catch (error) {
      // データの削除に失敗した場合、ログ表示して処理を継続
      logger.error(
        "[additionalDataForDeliverylist] 個人メモ画像のデータの削除に失敗しました。",
        {
          username: username,
        },
        error,
      );
    }
  },
};
export default additionalDataForDeliverylist;

/**
 * レコードの有無を取得する
 * @param {string} trackingNumber
 * @param {string} username
 * @returns {Promise<boolean>} レコード有無
 */
async function hasRecord(trackingNumber, username) {
  try {
    return (
      (await db.additionalDataForDeliverylist
        .where("trackingNumber")
        .equals(trackingNumber)
        .count()) > 0
    );
  } catch (error) {
    // データの有無チェックに失敗した場合、ログを表示してエラーをスローする
    logger.error(
      "[additionalDataForDeliverylist] 個人メモ画像のレコードチェックに失敗しました。",
      {
        username: username,
      },
      error,
    );
    throw error;
  }
}

/**
 * データを取得する
 * @param {string} trackingNumber
 * @param {string} username
 * @returns {Promise<import("~/libs/db").AdditionalDataForDeliverylist>}
 */
async function get(trackingNumber, username) {
  try {
    return await db.additionalDataForDeliverylist.get(trackingNumber);
  } catch (error) {
    // データの取得に失敗した場合、ログを表示してエラーをスローする
    logger.error(
      "[additionalDataForDeliverylist] 個人メモ画像の取得に失敗しました。",
      {
        username: username,
      },
      error,
    );
    throw error;
  }
}

/**
 * データを登録する
 * @param {import("~/libs/db").AdditionalDataForDeliverylist} record
 * @param {string} username
 */
async function regist(record, username) {
  try {
    await db.additionalDataForDeliverylist.add(record);
  } catch (error) {
    // データの登録に失敗した場合、ログを表示してエラーをスローする
    logger.error(
      "[additionalDataForDeliverylist] 個人メモ画像の登録に失敗しました。",
      {
        username: username,
      },
      error,
    );
    throw error;
  }
}

/**
 * データを更新する
 * @param {string} trackingNumber
 * @param {{personalMemoPhoto: string}} change
 * @param {string} username
 */
async function update(trackingNumber, change, username) {
  try {
    await db.additionalDataForDeliverylist.update(trackingNumber, change);
  } catch (error) {
    // データの更新に失敗した場合、ログを表示してエラーをスローする
    logger.error(
      "[additionalDataForDeliverylist] 個人メモ画像の更新に失敗しました。",
      {
        username: username,
      },
      error,
    );
    throw error;
  }
}
