<script>
  import Button from "@smui/button";
  import Checkbox from "@smui/checkbox";
  import FormField from "@smui/form-field";
  import IconButton, { Icon } from "@smui/icon-button";
  import Tooltip, { Wrapper } from "@smui/tooltip";
  import Accordion, {
    Header as AccordionHeader,
    Content,
    Panel,
  } from "@smui-extra/accordion";
  import { format as formatDate } from "date-fns";
  import { HTTPError } from "ky";
  import { getContext, onDestroy, onMount } from "svelte";
  import { fade } from "svelte/transition";
  import { _ } from "svelte-i18n";

  import Badge from "~/components/Badge.svelte";
  import ConfirmDialog from "~/components/ConfirmDialog.svelte";
  import Footer from "~/components/Footer.svelte";
  import Header from "~/components/Header.svelte";
  import QrCodeScanner from "~/components/QrCodeScanner.svelte";
  import RoleIcon from "~/components/RoleIcon.svelte";
  import addressUtils from "~/libs/addressUtils";
  import { beep, destroyAudioContext } from "~/libs/audio";
  import backendApi, { OfflineException } from "~/libs/backendApi";
  import {
    CONTEXT_KEY_USER,
    ConfirmDialogTypes,
    LastOperationTypes,
    NotificationCategory,
    QrHomeTypes,
    QrScanModes,
    ReturnReason,
    STATUS_CREATED,
    STATUS_HELD_IN_DEPOT,
    STATUS_IN_TRANSIT,
    STATUS_OUT_FOR_DELIVERY,
    STATUS_REQUESTING_FOR_RETURN,
    STATUS_RETURNED,
    STATUS_RETURNING,
    STATUS_WAITING_FOR_RETURN,
  } from "~/libs/constants";
  import depotLocations from "~/libs/depotLocations";
  import loadingProgress from "~/libs/loadingProgress";
  import logger from "~/libs/logger";
  import notificationHistoryUtils from "~/libs/notificationHistoryUtils";
  import pageRouter from "~/libs/pageRouter";
  import { CodeType } from "~/libs/qrCodeScanner";
  import { lastOperationType, updateCenter } from "~/libs/stores";
  import { toast } from "~/libs/toast";
  import {
    formatTrackingNumber,
    parseCodabarEmbeddedTrackingNumber,
    parseQRCodeEmbeddedTrackingNumber,
  } from "~/libs/trackingNumberUtils";

  export let selectReturnWork;

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  const centerId = $updateCenter ? $updateCenter.split("/")[0] : null;
  const centerName = $updateCenter ? $updateCenter.split("/")[1] : null;

  /** @type {Set<string>} */
  const decodedTextList = new Set();
  /** @type {Array<number>}*/
  const returnReasonList = [
    ReturnReason.REQUESTED_RETURN_FROM_EC,
    ReturnReason.REQUESTED_CANCEL_FROM_RECEIVER,
    ReturnReason.ACCEPT_DENIED,
    ReturnReason.ADDRESS_WRONG,
    ReturnReason.ADDRESS_UNKNOWN,
    ReturnReason.REDELIVERY_LIMIT_EXCEEDED,
    ReturnReason.SHIPMENT_PROBLEM,
  ];

  /** @type {QrCodeScanner} */
  let qrCodeScanner;

  /** @type {Array<ReturnPackage>} */
  let returnPackages = [];

  /**
   * @typedef {{
   *   trackingNumber: string,
   *   numberOfPackages: number,
   *   isManuallyInputted: boolean,
   *   status?: number,
   *   address?: string,
   *   correctedAddress?: string,
   *   receiverName?: string,
   *   returnedHistory?: {
   *     returnedTimeAndPlace: string,
   *     returnedStatus: string,
   *     returnedReason?: string
   *   }[],
   *   displayNoteOnRegistration: boolean,
   *   returnReason: number,
   *   displaySelectReturnReasonArea: boolean,
   *   displayNoteUnselectedReturnReason: boolean,
   *   multipleBoxesChecked: boolean,
   * }} ReturnPackage
   */

  let btnDisabled = true;
  let registering = false;
  let panelOpen = false;
  let num = 0;
  let badgeNum;
  let badge;

  /** 戻る確認ダイアログ @type {ConfirmDialog} */
  let backConfirmDialog;

  /** データ破棄確認後に実行する処理 @type {() => void} */
  let functionAfterDiscard;

  /** 配送センターIDをキーとしたセンター情報のMap @type {Map<number, import("~/libs/commonTypes").DepotLocation>} */
  let centersMap;

  /** 各荷物の確認事項（返品対象外、複数個口）が全て確認済みかどうか @type {boolean}*/
  let allChecked = true;

  /** スキャン失敗後に手入力案内メッセージを表示するまでのカウント中の場合にtrue @type {boolean} */
  let isCountingAfterFailedScan = false;

  // ページの初期化処理（非同期）
  (async () => {
    centersMap = await depotLocations.getCentersMap();
  })();

  onMount(
    loadingProgress.wrapAsync(async () => {
      await qrCodeScanner.startScanning();
    }),
  );

  onDestroy(() => {
    // AudioContextを破棄（iOSはAudioContextを破棄しないと次回起動時に音が鳴らなくなる）
    destroyAudioContext();

    // スキャン失敗メッセージのフラグはOFF
    isCountingAfterFailedScan = false;
  });

  async function updateStatuses() {
    registering = true;
    await loadingProgress.wrapAsync(applyUpdateToShippingList)();
  }

  async function applyUpdateToShippingList() {
    try {
      if (returnPackages.length) {
        // ステータス更新API実行
        const data = await execStatusUpdateApi();
        if (data && data.updateFailed) {
          const updateFailedNumbers = data.updateFailed;
          const outputTrackingNumbers = updateFailedNumbers
            .map((t) => formatTrackingNumber(t))
            .join(", ");
          console.log(outputTrackingNumbers);
          toast.error(
            $_("errors.failedToRegisterReturn", {
              values: { trackingNumber: outputTrackingNumbers },
            }),
          );
          notificationHistoryUtils
            .deleteAndAddHistory(
              userContext.loginUser.username,
              NotificationCategory.ERROR,
              $_("errors.failedToRegisterReturn", {
                values: { trackingNumber: outputTrackingNumbers },
              }),
            )
            .catch((error) => {
              // ユーザーによる能動的な操作ではないため、エラートーストは出さずに処理を継続する
              console.log(error);
            });
        } else {
          toast.info($_("message.updateCompleteReturnToEc"));
        }
        num = 0;
        decodedTextList.clear();
      }
      lastOperationType.set(LastOperationTypes.RETURN_TO_EC);
      pageRouter.moveToQrHome(QrHomeTypes.RETURN_TO_EC);
    } catch (error) {
      if (
        error instanceof HTTPError &&
        error.response &&
        error.response.status == 401
      ) {
        toast.error($_("errors.unauthorized"));
        logout();
      } else if (
        error instanceof HTTPError &&
        error.response &&
        error.response.status == 403
      ) {
        toast.error($_("errors.forbidden"));
        logout();
      } else {
        logger.error(
          "[ReturnToEcWithQrCodeScan] 返品登録でエラーが発生しました",
          {
            username: userContext.loginUser?.username,
            locationId: centerId,
            returnPackages: returnPackages.map((e) => ({
              trackingNumber: e.trackingNumber,
              isManuallyInputted: e.isManuallyInputted,
              status: e.status,
              returnedHistory: e.returnedHistory,
              displayNoteOnRegistration: e.displayNoteOnRegistration,
              returnReason: e.returnReason,
            })),
          },
          error,
        );
        // 返品登録はオフラインモード未対応のためエラーメッセージのみ表示
        if (error instanceof OfflineException) {
          toast.error($_("errors.offline"));
        } else {
          toast.error($_("errors.defaultMessage"));
        }
      }
    } finally {
      registering = false;
    }
  }

  async function execStatusUpdateApi() {
    let body = new FormData();
    let statusUpdateEvent = { response: true };
    let events = [];

    for (let i = returnPackages.length - 1; i >= 0; i--) {
      const event = {
        trackingNumber: returnPackages[i].trackingNumber,
        // 「出荷待ち」の場合は「輸送中」に、「持出中」の場合は「保管中」に更新。それ以外の場合はそのまま設定
        status:
          returnPackages[i].status === STATUS_CREATED
            ? STATUS_IN_TRANSIT
            : returnPackages[i].status === STATUS_OUT_FOR_DELIVERY
              ? STATUS_HELD_IN_DEPOT
              : returnPackages[i].status,
        returnStatus: selectReturnWork,
        returnReason: returnPackages[i].returnReason,
      };
      if (centerId !== null) {
        event.locationId = new Number(centerId);
      }
      if (returnPackages[i].isManuallyInputted) {
        event.isManuallyInputted = returnPackages[i].isManuallyInputted;
      }
      events.push(event);
    }
    statusUpdateEvent["events"] = events;
    let blob2 = new Blob([JSON.stringify(statusUpdateEvent)], {
      type: "application/json",
    });
    body.append("status-update-event", blob2);

    return backendApi.updateShipmentStatus(body);
  }

  /**
   * @param {string} decodedText
   * @param {boolean} needsDecode
   * @param {import("~/libs/qrCodeScanner").CodeType} codeType
   */
  async function onScanSuccess(decodedText, needsDecode, codeType) {
    let isManuallyInputted = false;
    const currentDecodedTextListSize = decodedTextList.size;

    /** @type {Error} */
    let parseError;
    try {
      if (needsDecode) {
        decodedText =
          codeType === CodeType.QRCODE
            ? parseQRCodeEmbeddedTrackingNumber(decodedText)
            : parseCodabarEmbeddedTrackingNumber(decodedText);
      } else {
        isManuallyInputted = true;
      }
    } catch (error) {
      parseError = error;
    } finally {
      decodedTextList.add(decodedText);
    }

    if (decodedTextList.size === currentDecodedTextListSize) {
      // スキャン済みのデータは無視
      return;
    } else if (parseError) {
      if (parseError instanceof TypeError && !isCountingAfterFailedScan) {
        // 形式エラーの場合、10秒後にスキャン成功していなければメッセージを表示する
        isCountingAfterFailedScan = true;
        setTimeout(() => {
          if (isCountingAfterFailedScan) {
            toast.error($_("errors.repeatedScanFailures"));
            isCountingAfterFailedScan = false;
          }
        }, 10000);
      }
      toast.error(parseError.message);
      return;
    }

    // スキャン成功しているためスキャン失敗メッセージのフラグはOFF
    isCountingAfterFailedScan = false;

    // 被りがないかチェック
    if (!returnPackages.some((e) => e.trackingNumber === decodedText)) {
      returnPackages.push({
        trackingNumber: decodedText,
        numberOfPackages: 1,
        isManuallyInputted: isManuallyInputted,
        address: "取得中...",
        receiverName: "",
        displayNoteOnRegistration: false,
        returnReason: null,
        displaySelectReturnReasonArea: false,
        displayNoteUnselectedReturnReason: false,
        multipleBoxesChecked: true,
      });
      returnPackages = returnPackages;
    }

    beep();
    badge = null;
    num = num + 1;
    badgeNum = num;
    badge = Badge;
    // QRコードスキャン情報取得APIの実行(非同期)
    execQrScanApi(decodedText);
  }

  function execQrScanApi(trackingNumber) {
    setTimeout("", 1000);
    backendApi
      .getShipmentInfoByQrScan(trackingNumber, QrScanModes.RETURN_TO_EC)
      .then((qrScanResponse) => {
        const index = returnPackages.findIndex(
          (e) => e.trackingNumber === trackingNumber,
        );
        if (index != -1) {
          if (!Number.isInteger(qrScanResponse.returnStatus)) {
            // 返品登録されていない場合
            returnPackages[index].displaySelectReturnReasonArea = true;
            allChecked = false;
          } else if (
            (selectReturnWork === STATUS_RETURNING &&
              qrScanResponse.returnStatus === STATUS_RETURNING) ||
            (selectReturnWork === STATUS_RETURNING &&
              qrScanResponse.returnStatus === STATUS_RETURNED) ||
            (selectReturnWork === STATUS_RETURNED &&
              qrScanResponse.returnStatus === STATUS_RETURNED)
          ) {
            // 既に更新後の返品ステータスになっている、もしくは返品済みの場合は、toastを表示してリストから削除する
            let message =
              qrScanResponse.returnStatus === STATUS_RETURNING
                ? $_("errors.alreadyReturning", {
                    values: {
                      trackingNumber: formatTrackingNumber(trackingNumber),
                    },
                  })
                : $_("errors.alreadyReturned", {
                    values: {
                      trackingNumber: formatTrackingNumber(trackingNumber),
                    },
                  });

            toast.error(message, { popsWhenPageMoved: true });
            deleteFromReturnPackages(trackingNumber);
            returnPackages = returnPackages;
            return;
          }

          returnPackages[index].status = qrScanResponse.status;
          returnPackages[index].returnReason = qrScanResponse.returnReason;
          returnPackages[index].address = addressUtils.trimPrefecture(
            qrScanResponse.address,
          );
          if (qrScanResponse.correctedReceiverAddress) {
            returnPackages[index].correctedAddress =
              addressUtils.trimPrefecture(
                qrScanResponse.correctedReceiverAddress,
              );
          }
          returnPackages[index].receiverName = qrScanResponse.receiverName;

          // 返品更新履歴の作成
          let alreadySetReturnReason = false;
          if (Number.isInteger(qrScanResponse.returnStatus)) {
            const returnedHistoryList = [];
            if (qrScanResponse.requestingForReturnAt) {
              // 返品要求中の更新日時が設定されている場合
              returnedHistoryList.push({
                returnedTimeAndPlace: formatDate(
                  new Date(qrScanResponse.requestingForReturnAt),
                  "yyyy/MM/dd HH:mm",
                ),
                returnedStatus: $_(
                  `classes.returnStatus.${STATUS_REQUESTING_FOR_RETURN}`,
                ),
                returnedReason: $_(
                  `classes.returnReason.${qrScanResponse.returnReason}`,
                ),
              });
              alreadySetReturnReason = true;
            }
            if (qrScanResponse.waitingForReturnAt) {
              // 返品待ちの更新日時が設定されている場合
              const displayReturnCenter = Number.isInteger(
                qrScanResponse.relayLocationId,
              )
                ? centersMap.get(qrScanResponse.relayLocationId).name
                : "";
              returnedHistoryList.push({
                returnedTimeAndPlace:
                  formatDate(
                    new Date(qrScanResponse.waitingForReturnAt),
                    "yyyy/MM/dd HH:mm",
                  ) +
                  " " +
                  displayReturnCenter,
                returnedStatus: $_(
                  `classes.returnStatus.${STATUS_WAITING_FOR_RETURN}`,
                ),
              });
              if (!alreadySetReturnReason) {
                returnedHistoryList[
                  returnedHistoryList.length - 1
                ].returnedReason = $_(
                  `classes.returnReason.${qrScanResponse.returnReason}`,
                );
                alreadySetReturnReason = true;
              }
            }
            if (qrScanResponse.returningAt) {
              // 返品中の更新日時が設定されている場合
              returnedHistoryList.push({
                returnedTimeAndPlace: formatDate(
                  new Date(qrScanResponse.returningAt),
                  "yyyy/MM/dd HH:mm",
                ),
                returnedStatus: $_(`classes.returnStatus.${STATUS_RETURNING}`),
              });
              if (!alreadySetReturnReason) {
                returnedHistoryList[
                  returnedHistoryList.length - 1
                ].returnedReason = $_(
                  `classes.returnReason.${qrScanResponse.returnReason}`,
                );
              }
            }
            returnPackages[index].returnedHistory = returnedHistoryList;
          } else {
            returnPackages[index].returnedHistory = [];
          }
          returnPackages[index].numberOfPackages =
            qrScanResponse.numberOfPackages;
          if (qrScanResponse.numberOfPackages > 1) {
            // 複数個口の場合
            returnPackages[index].multipleBoxesChecked = false;
            allChecked = false;
          }

          returnPackages = returnPackages;
        }
      })
      .catch((error) => {
        logger.error(
          "[ReturnToEcWithQrCodeScan] QRコードスキャンAPIの呼出・データ統合処理でエラーが発生しました",
          {
            username: userContext.loginUser?.username,
            locationId: centerId,
            trackingNumber: trackingNumber,
          },
          error,
        );
        if (error.response?.status === 400) {
          toast.error(
            $_("errors.ineligibleTrackingNumberOfReturn", {
              values: { trackingNumber: formatTrackingNumber(trackingNumber) },
            }),
            { initial: 0, popsWhenPageMoved: true },
          );
        } else if (error.response?.status === 401) {
          toast.error($_("errors.unauthorized"));
          logout();
        } else if (error.response?.status === 403) {
          toast.error($_("errors.forbidden"));
          logout();
        } else {
          toast.error(
            $_("errors.getPackageInfoFailed", {
              values: { trackingNumber: formatTrackingNumber(trackingNumber) },
            }),
            { popsWhenPageMoved: true },
          );
        }
        // 更新には更新前ステータスの取得が必須であるため、情報の取得に失敗した荷物をリストから削除
        deleteFromReturnPackages(trackingNumber);
      });

    if (btnDisabled) {
      btnDisabled = false;
    }
  }

  /**
   * 情報の取得に失敗した荷物をリストから削除する。
   * @param {string} trackingNumber
   */
  function deleteFromReturnPackages(trackingNumber) {
    returnPackages = returnPackages.filter(
      (e) => e.trackingNumber !== trackingNumber,
    );
    if (returnPackages.length === 0) {
      btnDisabled = true;
    }
    num = num - 1;
    if (num === 0) {
      badge = null;
    } else {
      badgeNum = num;
    }
  }

  function onScanError(errorMessage, error) {
    console.log(
      "[ReturnPackageToDepotWithQrCodeScan.svelte] onScanError:",
      errorMessage,
      error,
    );
  }

  function logout() {
    pageRouter.moveToLogin();
  }

  function goToBackPage() {
    num = 0;
    pageRouter.moveToQrHome(QrHomeTypes.RETURN_TO_EC);
  }

  /** 戻るボタン押下時の処理 */
  function confirmDiscardAndGoBack() {
    if (returnPackages.length > 0) {
      functionAfterDiscard = goToBackPage;
      backConfirmDialog.openDialog();
    } else {
      goToBackPage();
    }
  }

  /**
   * フッタボタン押下時の処理
   * @param {() => void} pageTransition
   */
  function confirmDiscardAndChangePage(pageTransition) {
    if (returnPackages.length > 0) {
      functionAfterDiscard = pageTransition;
      backConfirmDialog.openDialog();
    } else {
      pageTransition();
    }
  }

  /**
   * 各荷物の確認事項（返品対象外、複数個口）が全て確認済みかどうかをチェックする
   */
  function checkAllCheckpoints() {
    allChecked = true;
    for (let i = 0; i < returnPackages.length; i++) {
      if (
        !returnPackages[i].multipleBoxesChecked ||
        !Number.isInteger(returnPackages[i].returnReason)
      ) {
        allChecked = false;
      }
    }
  }
</script>

<div class="mainContentsWrapper">
  <Header>
    <svelte:fragment slot="left">
      {#if userContext.canSwitchRole()}
        <RoleIcon />
      {/if}
    </svelte:fragment>
    <svelte:fragment slot="center">
      <Wrapper rich>
        <span tabindex="0" role="button">幹線輸送返品登録 </span>
        {#if centerName && selectReturnWork === STATUS_RETURNING}
          <IconButton
            size="button"
            class="material-icons"
            style="vertical-align: sub;">warehouse</IconButton
          >
          <Tooltip xPos="start" unbounded>{centerName}</Tooltip>
        {/if}
      </Wrapper>
    </svelte:fragment>
  </Header>

  <main in:fade>
    <QrCodeScanner
      bind:this={qrCodeScanner}
      onScanSuccessHandler={onScanSuccess}
      onScanErrorHandler={onScanError}
      enableInputForm={true}
    />

    <div id="resultArea">
      <div class="listContents">
        <div class="accordion-container">
          <Accordion multiple>
            {#each returnPackages as item (item.trackingNumber)}
              <Panel>
                <div class="resultLine">
                  <div class="resultFlex">
                    <div class="resultInfo">
                      <div class="resultInfoHeader">
                        <p class="tracking">
                          <!-- 複数個口の場合は個数を表示-->
                          {formatTrackingNumber(
                            item.trackingNumber,
                          )}{#if item.numberOfPackages > 1}<span
                              class="quantity"
                              >（全{item.numberOfPackages}個）</span
                            >{/if}
                        </p>
                      </div>
                      <p class="address">
                        {#if item.correctedAddress}
                          <span class="highlight">
                            {item.correctedAddress}
                            <span class="note">※訂正された住所</span>
                          </span>
                        {:else}
                          {item.address}
                        {/if}
                      </p>
                      <p class="name">{item.receiverName}</p>
                    </div>
                    <div class="button">
                      <AccordionHeader
                        style="width: fit-content; border-radius: 50%;"
                      >
                        <IconButton slot="icon" toggle pressed={panelOpen}>
                          <Icon class="material-icons" on>expand_less</Icon>
                          <Icon class="material-icons">expand_more</Icon>
                        </IconButton>
                      </AccordionHeader>
                    </div>
                  </div>
                  {#if item.displaySelectReturnReasonArea}
                    <!-- 返品対象未登録の場合 -->
                    <div class="warningText">
                      この荷物は返品対象として事前登録されていない荷物です。宅配事業者へ、混入でないことと返品理由を確認してください。
                      <p class="returnTargetInfo">
                        <span class="returnTargetInfoLabel">返品理由</span>
                        <select
                          class="selectReturnReason"
                          bind:value={item.returnReason}
                          on:change={checkAllCheckpoints}
                        >
                          <option selected disabled
                            >返品理由を選択してください</option
                          >
                          {#each returnReasonList as returnReason}
                            <option value={returnReason}
                              >{$_(
                                `classes.returnReason.${returnReason}`,
                              )}</option
                            >
                          {/each}
                        </select>
                      </p>
                      <p
                        class="unselectedNote"
                        class:displayNote={item.displayNoteUnselectedReturnReason}
                      >
                        返品理由が選択されていません。
                      </p>
                      <div class="deleteButtonArea">
                        <Button
                          variant="outlined"
                          style="border-color: #1976d2;"
                          on:click={() => {
                            deleteFromReturnPackages(item.trackingNumber);
                            checkAllCheckpoints();
                          }}>キャンセル</Button
                        >
                        <Button
                          variant="unelevated"
                          on:click={() => {
                            if (!Number.isInteger(item.returnReason)) {
                              item.displayNoteUnselectedReturnReason = true;
                            } else {
                              item.displaySelectReturnReasonArea = false;
                              checkAllCheckpoints();
                            }
                          }}>OK</Button
                        >
                      </div>
                    </div>
                  {/if}
                  {#if item.numberOfPackages > 1}
                    <!-- 複数個口の場合 -->
                    <div class="warningText">
                      <FormField>
                        <Checkbox
                          bind:checked={item.multipleBoxesChecked}
                          on:change={checkAllCheckpoints}
                        />
                        <span slot="label"
                          >荷物が{item.numberOfPackages}個あることを確認した</span
                        >
                      </FormField>
                    </div>
                  {/if}
                </div>
                {#if item.returnedHistory}
                  <Content style="padding: 0;">
                    <div class="returnedHistoryArea">
                      {#if item.returnedHistory.length}
                        <div class="returnedHistory">
                          {#each item.returnedHistory as returnedInfo}
                            <div class="returnedHistoryItem">
                              <p class="returnedTimeAndPlace">
                                {returnedInfo.returnedTimeAndPlace}
                              </p>
                              <div class="returnedHistoryItemInfo">
                                <p>
                                  {returnedInfo.returnedStatus}
                                </p>
                                {#if returnedInfo.returnedReason}
                                  <p>({returnedInfo.returnedReason})</p>
                                {/if}
                              </div>
                            </div>
                          {/each}
                        </div>
                      {:else}
                        <p class="nothingReturnedHistory">
                          過去に返品ステータスの登録はありません。
                        </p>
                      {/if}
                    </div>
                  </Content>
                {/if}
              </Panel>
            {/each}
          </Accordion>
        </div>
        <div style="height: 70px; background-color: #fff;"></div>
      </div>
    </div>

    <div class="buttonArea">
      <button class="backBtn" on:click={confirmDiscardAndGoBack}>戻る</button>
      <button
        class:confirmBtn={!btnDisabled && allChecked && !registering}
        class:disabledBtn={btnDisabled || !allChecked || registering}
        disabled={btnDisabled || !allChecked || registering}
        on:click={updateStatuses}
        >{#if badgeNum > 0}<svelte:component this={badge} {badgeNum} />{/if}
        {#if !registering}確定{:else}登録中{/if}
      </button>
    </div>
  </main>

  <Footer {confirmDiscardAndChangePage} />
</div>
<div class="subContentsWrapper">
  <!-- 戻るダイアログ -->
  <ConfirmDialog
    bind:this={backConfirmDialog}
    mandatory={true}
    type={ConfirmDialogTypes.OK_CLOSE}
    onDialogClosedHandler={(event) => {
      if (event.detail.action === "ok") {
        functionAfterDiscard();
      }
    }}
  >
    <svelte:fragment slot="title">確認</svelte:fragment>
    <svelte:fragment slot="content"
      >読み込んだ荷物情報は破棄されます。<br />よろしいですか？</svelte:fragment
    >
  </ConfirmDialog>
</div>

<style lang="scss">
  main {
    display: flex;
    flex-direction: column;
  }

  #resultArea {
    flex: 1;
    overflow-y: auto;
    width: 100%;
    background-color: #fff;
  }

  .resultLine {
    padding: 10px 4px 10px 15px;
    border-bottom: 1px solid #ccc;
  }

  .resultFlex {
    margin-left: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;

    .resultInfo {
      display: column;
      padding: 3px 0;
      line-height: 22px;

      .resultInfoHeader {
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 4px;
      }

      .tracking {
        .quantity {
          color: #c62800;
        }
      }

      .address {
        .highlight {
          padding: 3px;
          border-radius: 4px;
          background-color: #facfcf;
        }

        .note {
          font-size: 11.4px;
          color: #c80000;
        }
      }
    }
  }

  .warningText {
    font-size: 14px;
    margin-top: 5px;
    background-color: #ffe7e7;
    color: #c80000;
    border-radius: 4px;
    padding: 8px 10px;
    width: calc(100% - 30px);
    line-height: 1.4;

    :global(.mdc-form-field) {
      color: #c80000;
    }
    .returnTargetInfo {
      display: flex;
      font-size: 15px;
      line-height: 40px;
      margin-top: 10px;

      .returnTargetInfoLabel {
        display: inline-block;
        width: 100px;
        border-radius: 7px;
        margin: 0 10px 5px 0;
        background-color: #018786;
        color: #fff;
        font-weight: bold;
        text-align: center;
      }

      select {
        height: 40px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 15px;
        color: black;
      }
    }
    .unselectedNote {
      font-size: 13px;
      color: red;
      display: none;
    }
    .displayNote {
      display: block;
    }
    .deleteButtonArea {
      margin-top: 8px;
      text-align: right;
    }
  }

  .returnedHistoryArea {
    padding: 3px 10px 7px;

    .returnedHistory {
      padding-left: 6px;
      margin: 3px 0 2px 10px;
      border-left: 5px solid #018786;
      font-size: 0.9em;
      line-height: 1.3em;

      .returnedHistoryItem {
        margin-top: 5px;
        padding-left: 10px;

        .returnedTimeAndPlace {
          font-size: 0.9em;
          color: #018786;
        }

        .returnedHistoryItemInfo {
          margin-left: 8px;
          font-size: 0.9em;
        }
      }
    }

    .nothingReturnedHistory {
      padding-left: 10px;
      font-size: 0.8em;
    }
  }

  .buttonArea {
    button {
      width: 60px;
      height: 60px;
      border: none;
      border-radius: 50%;
      font-weight: bold;
      color: #fff;
    }

    .backBtn {
      position: fixed;
      font-size: 16px;
      bottom: calc(70px + var(--app-inset-bottom-padding));
      left: 25px;
      padding: 0;
      margin: 0;
      background-color: #018786;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
    }

    .confirmBtn {
      position: fixed;
      font-size: 16px;
      bottom: calc(70px + var(--app-inset-bottom-padding));
      right: 25px;
      padding: 0;
      margin: 0;
      background-color: #018786;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
    }
  }

  .buttonArea {
    :global(.smui-badge.smui-badge--color-primary) {
      background-color: red;
    }

    .disabledBtn {
      position: fixed;
      font-size: 16px;
      bottom: calc(70px + var(--app-inset-bottom-padding));
      right: 25px;
      padding: 0;
      margin: 0;
      background-color: #ccc;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
    }
  }
</style>
